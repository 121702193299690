/**
 * @typedef {Object} Tag
 * @property {string} type
 * @property {string} name
 *
 * @typedef {Object} PageHit
 * @property {string} id
 * @property {"PAGE"} type
 * @property {string} headline
 * @property {string} description
 * @property {string} relativeUrl
 * @property {{url: string, altText: string}} previewImage
 * @property {Tag[]} tags
 * @property {string} body
 * @property {string} objectID
 *
 * @typedef {Object} ResultItem
 * @property {string} url
 * @property {Tag[]} tags
 * @property {string} headline
 * @property {string} imageSrc
 * @property {string} subCopy
 *
 * @param {PageHit} hit
 * @returns {ResultItem}
 */
export function transformHit(hit) {
  return {
    url: hit.relativeUrl,
    tags: hit.tags ?? [],
    headline: hit.headline ?? "",
    imageSrc: hit.previewImage?.url ?? "",
    subCopy: hit.previewText ?? ""
  };
}

/**
 * @typedef {Object} Suggestion
 * @property {string} id
 * @property {string} objectID
 *
 * @typedef {Object} SearchQuerySuggestionProps
 * @property {string} id
 * @property {string} query
 * @property {string[]} items
 *
 * @param {Suggestion} suggestions
 * @returns {SearchQuerySuggestionProps}
 *
 */
export function transformSuggestions(suggestions) {
  return suggestions?.map((suggestion) => {
    return suggestion.query;
  });
}

/**
 *
 * @param {string} query
 * @returns {string}
 */
export const formatQuery = (query = "") => {
  return query ? `“${query[0]?.toUpperCase() + query.slice(1)}”` : "";
};

/**
 *
 * @param {Object[]} results
 * @param {number} resultsPerPage
 * @returns {Object[][]}
 */
export const paginateResults = (results = [], resultsPerPage = 10) => {
  const numOfPages = Math.ceil(results?.length / resultsPerPage);
  const paginatedResults = Array(numOfPages || 0)
    .fill()
    .map((_, i) => {
      return results.slice(i * resultsPerPage, (i + 1) * resultsPerPage);
    });

  return paginatedResults;
};

/**
 *
 * @param {ResultItem[]} results
 * @returns {Tag[]}
 */
export function collectTagsFromResults(results = []) {
  return results.reduce((collector, hit) => {
    collector = collector.concat(hit.tags);
    return collector;
  }, []);
}

/**
 * group tags by type
 *
 * @typedef {{[key: string]: string[]}} TagGroups map keyed to type
 *
 * @param {string[]} tags
 * @returns {TagGroups}
 */
export function groupTags(tags = []) {
  return tags.reduce((group, tag) => {
    const { type, name } = tag;
    if (group[type]) {
      // dedupe
      group[type] = Array.from(new Set([...group[type], name]));
    } else {
      group[type] = [name];
    }
    return group;
  }, {});
}

/**
 * transform tag groups into list of filters
 *
 * @param {TagGroups} tagGroups
 */
export function tagGroupsToFilters(tagGroups = {}) {
  return Object.entries(tagGroups).map(([type, tags]) => ({
    fields: {
      name: type,
      tags: tags.map((tag) => ({
        fields: {
          name: tag,
          type
        }
      }))
    }
  }));
}

/**
 * find the tag types for all active filters and group them by tag type
 *
 * @typedef {Object} ActiveFilter
 * @property {string} id
 * @property {string} label
 *
 * @param {ActiveFilter[]} activeFilters
 * @param {TagGroups} tagGroups
 */
export function groupActiveFilters(activeFilters = [], tagGroups = {}) {
  return activeFilters.reduce((groups, filter) => {
    // search for the tag associated with filter since filter itself doesn't contain tag group/type
    const [filterGroupName] = Object.entries(tagGroups).find(([, tags]) =>
      tags.includes(filter.label)
    );

    if (groups[filterGroupName]) {
      // dedupe
      groups[filterGroupName] = Array.from(new Set([...groups[filterGroupName], filter.label]));
    } else {
      groups[filterGroupName] = [filter.label];
    }

    return groups;
  }, {});
}

/**
 * filter results that contain at least one tag in every active filter group
 *
 * @param {*} results
 * @param {*} activeFilterGroups
 * @returns
 */
export function filterResults(results = [], activeFilterGroups = {}) {
  return results.filter((result) => {
    // for every active tag group, result must contain at least one of the active tags
    return Object.entries(activeFilterGroups).every(([filterGroupName, activeFilters]) => {
      return activeFilters.some((activeFilter) =>
        result.tags.some((tag) => tag.type === filterGroupName && tag.name === activeFilter)
      );
    });
  });
}
